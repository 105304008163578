<template>
  <div>
    <!-- Drawer抽屉 -->
    <el-dialog :close-on-click-modal="false"  :title='title' :visible.sync="visible" width="476px" >
      <div :style="{maxHeight: maxHeight}" class="drawer-content">
        <Form ref="form" :model="form" :rules="formValidate"  label-position="top">
          <Row :gutter="16">
            <Col span="12">
              <FormItem label="姓名" prop="nickName" >
                <Input v-model="form.nickName" clearable/>
              </FormItem>
            </Col>
            <Col span="12">
              <FormItem label="手机号码" prop="tel" >
                <Input v-model="form.tel" clearable/>
              </FormItem>
            </Col>
            <Col span="12">
              <FormItem label="登录帐号" prop="userName" >
                <Input v-model="form.userName" clearable/>
              </FormItem>
            </Col>
            <Col span="12">
              <FormItem label="登录密码" prop="passWord" >
                <Input v-model="form.passWord" type="password" clearable/>
              </FormItem>
            </Col>
            <Col span="24">
                <FormItem label="备注信息" prop="remark" >
                  <Input v-model="form.remark" type="textarea" :rows="4" maxlength="250" show-word-limit/>
                </FormItem>
              </Col>
          </Row>
        </Form>
      </div>
      <div class="drawer-footer br" style="text-align:right" slot="footer" v-show="type!='0'">
        <el-button  type="primary" :loading="submitLoading" @click="submit">提交</el-button>
        <el-button @click="visible = false">取消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
// 根据你的实际请求api.js位置路径修改
import { saveCheckUser, updateCheckUser } from "@/api/index";
export default {
  name: "addEdit",
  components: {
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    data: {
      type: Object
    },
    type: {
      type: String,
      default: "0"
    }
  },
  data() {
    return {
      visible: this.value,
      title: "",
      submitLoading: false,
      maxHeight: 510,
      form: { // 添加或编辑表单对象初始化数据
        nickName: "",
        tel: "",
        userName: "",
        passWord: "",
        remark: "",
        userType: 1,
      },
      // 表单验证规则
      formValidate: {
        nickName: [{ type: "string", required: true, message: "请输入", trigger: "blur" }],
        tel: [{ type: "string", required: true, message: "请输入", trigger: "blur" }],
        userName: [{ type: "string", required: true, message: "请输入", trigger: "blur" }],
        passWord: [{ type: "string", required: true, message: "请输入", trigger: "blur" }],
    
      }
    };
  },
  methods: {
    init() {
    },
    submit() {
      this.$refs.form.validate(valid => {
        if (valid) {
          if (this.type == "1") {
            // 编辑
            this.submitLoading = true;
            updateCheckUser(this.form).then(res => {
              this.submitLoading = false;
              if (res.success) {
                this.$Message.success("操作成功");
                this.$emit("on-submit", true);
                this.visible = false;
              }
            });
          } else {
            // 添加
            this.submitLoading = true;
            saveCheckUser(this.form).then(res => {
              this.submitLoading = false;
              if (res.success) {
                this.$Message.success("操作成功");
                this.$emit("on-submit", true);
                this.visible = false;
              }
            });
          }
        }
      });
    },
    setCurrentValue(value) {
      if (value === this.visible) {
        return;
      }
      if (this.type == "1") {
        this.title = "编辑";
        this.maxHeight =
          Number(document.documentElement.clientHeight - 121) + "px";
      } else if (this.type == "2") {
        this.title = "添加";
        this.maxHeight =
          Number(document.documentElement.clientHeight - 121) + "px";
      } else {
        this.title = "详细信息";
        this.maxHeight = "100%";
      }
      // 清空数据
      //this.$refs.form.resetFields();
      if (this.type == "0" || this.type == "1") {
        // 回显数据处理
        this.form = this.data;
      } else {
        // 添加
        this.form = {
        nickName: "",
        tel: "",
        userName: "",
        passWord: "",
        remark: "",
        userType: 1,
        }
        //delete this.form.id;
      }
      this.visible = value;
    }
  },
  watch: {
    value(val) {
      // alert(val)
      console.log(val)
      this.setCurrentValue(val);
    },
    visible(value) {
      this.$emit("input", value);
    }
  },
  mounted() {
    this.init();
  }
};
</script>

<style lang="less">
@import "@/styles/drawer-common.less";
</style>